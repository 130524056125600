






















































































































































































































































































































































.notification-msgManage {
  position: fixed;
  width: 300px;
  right: 0;
  bottom: 0;
  margin: 20px;
  padding: 0;
  border: none;
  box-shadow: 0px 6px 20px 0px rgba(234, 234, 234, 0.86);
  border-radius: 4px;
  background: #fff;
  z-index: 9999;

  .notification-hd {
    height: 36px;
    line-height: 36px;
    padding: 0 0 0 12px;
    color: #fff;
    font-weight: normal;
    border-radius: 4px 4px 0 0;
    font-size: 16px;
    /*background:linear-gradient(-22deg,rgba(39,203,203,1) 0%,rgba(115,225,225,1) 100%);*/
    background: rgba(39, 203, 203, 1) url('../../assets/images/bg-notification-title.png') no-repeat center center;
    background-size: 100% 100%;

    &:before {
      position: relative;
      display: inline-block;
      content: '';
      width: 16px;
      height: 18px;
      margin-right: 8px;
      vertical-align: -4px;
      background: url('../../assets/images/icon-notice.png') no-repeat center center;

    }

    .close {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
      width: 36px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      color: #fff;
      font-size: 20px;
      text-align: center;
      transition: linear .2s;

      &:hover {
        transform: rotate(90deg);
      }
    }
  }

  .notification-content {
    max-height: 200px;
    margin: 8px 8px;
    padding: 0 12px;
    background: #fff;
    overflow: auto;

    .item {
      cursor: pointer;
      line-height: 1.4em;
      color: #333;
      font-size: 14px;
      padding: 4px 0;

      em {
        color: #19AFAF;
      }

      .content {
        margin-right: 10px;
        word-break: break-all;
      }

      .time {
        display: inline-block;
        color: #666;
      }

      &:hover .time,
      &:hover {
        color: #19AFAF;
      }

    }
  }
}

.slide-notice-enter-active {
  transition: linear .2s;
}

.slide-notice-enter {
  transform: translate3d(200px, 0, 0);
  opacity: 0;
}

.slide-notice-leave-to {
  opacity: 0;
}

