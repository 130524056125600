
















.panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 6px 20px 0px rgba(235, 235, 235, 0.86);
  border-radius: 10px;
  background: #fff;
  .panel__bd {
    flex: 1;
    height: 373px;
    overflow: auto;
  }
}
